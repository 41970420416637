.App {
    background-color: #b28e35;
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

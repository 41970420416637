.volume {
    background-color: transparent;
    font-size: xx-large;
    display: block;
    border: 0;
    position: absolute;
    right: 1%;
    top: 1%;
    color: transparent;
    text-shadow: 0 0 0 black;
    z-index: 1;
}

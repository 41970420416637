.score {
    color: white;
    font-family: "Chimboz Heavy";
    text-align: center;
    font-size: 150px;
}

.playerA {
    color: #ff3300;
}

.playerB {
    color: #00cc00;
}

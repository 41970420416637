.tile {
    width: 100px;
    height: 100px;
    padding: 0;
    margin: 0;
    border: 0;
    float: left;
    background-color: transparent;
}

.wall {
    background-image: url('tiles/assets/wall.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 50%;
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
}

.empty {
    background-image: url('tiles/assets/empty.png');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center 85%;
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
}

.player {
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
    padding-top: 10%;
    background-image: url('tiles/assets/shadow.png');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50% 85%;
}

.hasNotJoinedYet {
    filter: grayscale(1);
}

.playable {
    cursor: pointer;
}

.movable {
    background-color: white;
    mask-image: url('tiles/assets/copiable.png');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center 100%;
}

.jumpable {
    background-color: white;
    mask-image: url('tiles/assets/jumpable.png');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center 100%;
}

.row {
    height: 100px;
    margin: auto;
}

.board {
    background: rgb(178,142,53);
    background: linear-gradient(0deg, rgba(178,142,53,1) 0%, rgba(214,177,92,1) 100%);
    margin: auto;

    border: 5px solid white;
    border-radius: 3% 3% 5% 5%;
    border-color: #3c3d1c #313218 #2c2d16 #313218;
}
